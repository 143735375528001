.message {
    min-width: 350px;
    border-left: solid 5px;
    box-shadow: none;
    -webkit-box-shadow: none;
    border-radius: 0 !important;
    font-size: 1em;
    position: fixed !important; /* Use fixed to keep it in place during scrolling */
    z-index: 100;
    right: 1em;
    top: 50%; /* Aligns vertically to the middle */
    transform: translateY(-50%); /* Adjusts position to truly center vertically */
    margin: 0 !important;
}
